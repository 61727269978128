import React from 'react';
import {Layout} from 'components/pageLayouts';
import {Card} from 'components/molecules';
import styles from './styles.module.scss';

const Index = () => {
	return (
		<Layout className={styles.wrapper}>
			<Card title={'Type'} link={'/type'}>
				test
			</Card>
			<Card title={'p5 sketches'} link={'/canvas'}>
				test
			</Card>
			<Card title={'Three.js'} link={'/3D'}></Card>
		</Layout>
	);
};

export default Index;
